import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private text = signal('');
  constructor() {}

  getText() {
    return this.text();
  }

  showToast(message: string): void {
    this.text.set(message);
    setTimeout(() => this.text.set(''), 3000);
  }
}

